:root {
  --one-unit: 8px;
  --half-unit: calc(var(--one-unit) / 2);
  --two-units: calc(var(--one-unit) * 2);
  --three-units: calc(var(--one-unit) * 3);
  --four-units: calc(var(--one-unit) * 4);
  --five-units: calc(var(--one-unit) * 5);
  --six-units: calc(var(--one-unit) * 6);
  --seven-units: calc(var(--one-unit) * 7);
  --eight-units: calc(var(--one-unit) * 8);
  --border-width: 1px;
}
