.intro {
  background: #000;
  color: #fff;
  height: 100%;
  font-family: monospace;
  font-size: 30px;
  padding: 50px;
}

.intro__what {
  border-radius: 0;
  border: 1px solid #E54B69;
  background: black;
  color: white;
  font-family: monospace;
  font-size: 40px;
  display: block;
  padding: 20px 40px;
  margin: 20px auto;
  width: 100%;
  cursor: pointer;
}

.glitch {
  display: block;
  position: relative;
  margin: 0 auto;
}

@keyframes noise-anim {
  0% {
    clip-path: inset(35% 0 46% 0);
  }
  5% {
    clip-path: inset(54% 0 27% 0);
  }
  10% {
    clip-path: inset(63% 0 35% 0);
  }
  15% {
    clip-path: inset(31% 0 22% 0);
  }
  20% {
    clip-path: inset(60% 0 16% 0);
  }
  25% {
    clip-path: inset(69% 0 10% 0);
  }
  30% {
    clip-path: inset(75% 0 26% 0);
  }
  35% {
    clip-path: inset(60% 0 20% 0);
  }
  40% {
    clip-path: inset(74% 0 24% 0);
  }
  45% {
    clip-path: inset(84% 0 12% 0);
  }
  50% {
    clip-path: inset(27% 0 73% 0);
  }
  55% {
    clip-path: inset(42% 0 39% 0);
  }
  60% {
    clip-path: inset(86% 0 13% 0);
  }
  65% {
    clip-path: inset(26% 0 28% 0);
  }
  70% {
    clip-path: inset(72% 0 7% 0);
  }
  75% {
    clip-path: inset(65% 0 36% 0);
  }
  80% {
    clip-path: inset(50% 0 5% 0);
  }
  85% {
    clip-path: inset(16% 0 77% 0);
  }
  90% {
    clip-path: inset(65% 0 7% 0);
  }
  95% {
    clip-path: inset(69% 0 21% 0);
  }
  100% {
    clip-path: inset(79% 0 3% 0);
  }
}
.glitch::after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: white;
  background: black;
  overflow: hidden;
  animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
  0% {
    clip-path: inset(79% 0 21% 0);
  }
  5% {
    clip-path: inset(34% 0 2% 0);
  }
  10% {
    clip-path: inset(5% 0 60% 0);
  }
  15% {
    clip-path: inset(54% 0 5% 0);
  }
  20% {
    clip-path: inset(49% 0 10% 0);
  }
  25% {
    clip-path: inset(11% 0 43% 0);
  }
  30% {
    clip-path: inset(89% 0 6% 0);
  }
  35% {
    clip-path: inset(4% 0 55% 0);
  }
  40% {
    clip-path: inset(65% 0 36% 0);
  }
  45% {
    clip-path: inset(23% 0 71% 0);
  }
  50% {
    clip-path: inset(20% 0 67% 0);
  }
  55% {
    clip-path: inset(61% 0 28% 0);
  }
  60% {
    clip-path: inset(11% 0 48% 0);
  }
  65% {
    clip-path: inset(58% 0 39% 0);
  }
  70% {
    clip-path: inset(89% 0 2% 0);
  }
  75% {
    clip-path: inset(4% 0 86% 0);
  }
  80% {
    clip-path: inset(62% 0 8% 0);
  }
  85% {
    clip-path: inset(41% 0 12% 0);
  }
  90% {
    clip-path: inset(99% 0 1% 0);
  }
  95% {
    clip-path: inset(58% 0 7% 0);
  }
  100% {
    clip-path: inset(41% 0 50% 0);
  }
}
.glitch::before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: red;
  background: black;
  overflow: hidden;
  animation: noise-anim-2 15s infinite linear alternate-reverse;
}
