.thread {
  list-style: none;
}

.thread__link {
  padding: var(--three-units);
  padding-bottom: calc(var(--two-units) - var(--border-width));
  border-bottom: var(--border-width) solid #020102;

  text-decoration: none;
  color: #fff;

  display: flex;
  align-items: center;
}

.thread__photo {
  height: var(--six-units);
  width: var(--six-units);
  border-radius: var(--six-units);
}

.thread__text {
  font-size: var(--two-units);
  line-height: var(--two-units);
  margin-left: var(--three-units);
}

.thread--hasUnread {
  font-weight: bold;
}

.thread--hasUnread .thread__photo {
  border: 2px solid #120b18;
  box-shadow: 0 0 0 6px #7a00ff;
  /* box-shadow: 0 0 0 6px #E54B69; */
}
