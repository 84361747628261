.chatToolbar {
  background: #372C3A;
  border-bottom: 2px solid #120B18;
  display: flex;
  align-items: center;
  padding: var(--one-unit);
}

.chatToolbar__back-link {
  height: var(--six-units);
  width: var(--six-units);
  text-align: center;
  padding: var(--two-units);
}

.chatToolbar__back-icon {
  height: var(--two-units);
  width: var(--two-units);
}

.chatToolbar__photo {
  height: var(--four-units);
  width: var(--four-units);
  border-radius: var(--four-units);
  margin: 0 var(--two-units);
}

.chatToolbar__title {
  font-size: var(--two-units);
  line-height: var(--two-units);
  font-weight: bold;
  color: #fff;
}
