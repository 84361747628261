.choice {
  background: #000;
  color: white;
  text-align: center;
  padding: var(--one-unit);
  padding-bottom: 0;
  overflow: hidden;
  color: gray;
}

.choice__option {
  text-decoration: none;
  display: block;
  background: #836f88;
  color: #fff;
  padding: var(--two-units);
  margin-bottom: var(--one-unit);
  border: none;
  border-bottom: 2px solid rgba(0,0,0,0.5);
  border-radius: var(--half-unit);
  font-family: 'Recursive', sans-serif;
  font-weight: 500;
  width: 100%;
  font-size: 14px;
  line-height: var(--two-units);
}

.choice__noChoice {
  display: flex;
  justify-content: center;
  margin: var(--one-unit);
  color: #fff;
  margin-bottom: var(--two-units);
}

.choice__back-icon {
  float: left;
  margin-top: -4px;
}

.choice__typing {
  margin-left: var(--one-unit);
	font-size: var(--one-unit);
	width: var(--five-units);
  display: flex;
  align-items: center;
}

.choice__typing > div {
  margin-right: var(--half-unit);
  width: var(--one-unit);
  height: var(--one-unit);
  border-radius: var(--one-unit);
  background: #72777d;
  animation: fade 2s ease infinite;
  opacity: 0;
}

.choice__typing > div:nth-child(2) {
  animation-delay: 0.2s;
}

.choice__typing > div:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
