html,
body,
.App {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
}

body {
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", 'Inter', "Helvetica Neue", sans-serif;
  background: #130B19;
  background: #050307;
}
