.chatWindow-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
}

.chatWindow-container__toolbar {

}

.chatWindow-container__messages {

  flex: 1 1 100%;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;
  overflow: auto;
}

.chatWindow-container__choice {

}
