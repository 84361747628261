#root {
  height: 100%;
  width: 100%;
  background: #130B19;
}

.siteInfo {
  display: none;
}

.siteInfo h1 {
  color: #E54B69;
  font-weight: bold;
  font-size: var(--three-units);
}

.siteInfo a {
  color: white;
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  #root {
    border: 20px solid black;
    border-radius: 20px;
    width: 450px;
    overflow: hidden;
    height: 90vh;
    margin: 5vh auto;
  }

  .siteInfo {
    display: block;
    color: white;
    position: absolute;
    bottom: 10vh;
    right: 10vh;
  }
}
