/* This is trump CSS
 * Meant to override evenything so that the diary can have a different look
 *
 * This will affect everything that is:
 * threadName: Diary
 * Message from: D
 */
.chatWindow-container__toolbar--Diary .chatToolbar{
  font-family: monospace;
}

 .chatWindow-container__messages--Diary {
   font-family: monospace;
   font-size: 18px;
   background: #050307;
 }

.chatWindow-container__choice--Diary .choice {

}

.message--D .message__avatar {
  display: none;
}

.message--D .message__text {
  border-radius: 0;
  background: #050307;
  color: #178e78;
}
