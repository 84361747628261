.message {
  list-style: none;
  display: flex;
  align-items: flex-end;
  margin: var(--three-units) var(--one-unit) ;
}

.message__avatar {
  height: var(--six-units);
  width: var(--six-units);
  border-radius: var(--six-units);
}

.message__text {
  transition: 1s background-color ease;
  background: #332639;
  color: #fff;
  border-radius: var(--two-units);
  border-bottom-left-radius: 0;
  padding: var(--two-units);
  font-size: var(--two-units);
  line-height: var(--three-units);
  margin-left: var(--one-unit);
}

.message__text--stutter {
  animation: stutter 500ms 4;
  animation-delay: 300ms;
}

.message--you {
  flex-direction: row-reverse;
}

.message--you .message__text {
  background-color: #332827;
  border-radius: var(--two-units);
  border-bottom-right-radius: 0;
  margin-left: 0;
  margin-right: var(--one-unit);
}

.message--unseen .message__text {
  background: #3e1857;
}

@keyframes stutter {
  0% {
    text-transform: uppercase;
  }
  100% {
    text-transform: none;
  }
}
