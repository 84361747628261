.About {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.About__toolbar {
  background: #372C3A;
  border-bottom: 2px solid #120B18;
  padding: var(--three-units);
  font-weight: bold;
  color: #fff;
}

.About__toolbar__icon {
  float: right;
}

.About__content {
  color: #fff;
  background: #180F24;
  height: 100%;
}

.About__content_banner {
  width: 100%;
}

.About__content__text {
  margin: var(--three-units);
  margin-top: 0;
  font-size: 17px;
  line-height: 1.5;
  text-align: center;
}

.About__content_external-button {
  height: var(--four-units);
}

.About__content__help {
  font-size: 12px;
  text-align: center;
}

.About__content_reset-button {
  /* border: 4px solid #58B999; */
  border: none;
  font-weight: bold;
  background: #A9BCDE;
  text-transform: uppercase;
  color: #2C1641;
  padding: var(--one-unit) var(--two-units);
  margin-top: var(--one-unit);
  border-radius: var(--half-unit);
}

.About__content a {
  color: #fff;
}
