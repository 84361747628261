.threadToolbar {
  background: #372C3A;
  padding: var(--three-units);
}

.threadToolbar__title {
  font-size: var(--two-units);
  line-height: var(--two-units);
  font-weight: bold;
  color: #fff;
  display: inline-block;
}

.threadToolbar__icon {
  float: right;
}
